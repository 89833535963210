import { FaPhoneAlt } from 'react-icons/fa';
import styled from 'styled-components';
import { blue, darkGreen } from '../../styles/Variables';

export const Container = styled.div`
    position: fixed;
    top: 0;
    right: 20px;
    width: 160px;
    height: 100px;
    text-align: center;
    background-color: rgba(244, 244, 244, 0.8);
    z-index: 1;

    @media (max-width: 1400px) {
        display: none;
    }
`;

export const PhoneIcon = styled(FaPhoneAlt)`
    fill: #8e8e8e;
    font-size: 4rem;
    position: fixed;
    top: 18px;
    right: 145px;
    transform: rotate(20deg);
`;

export const PhoneText = styled.h2`
    margin-top: 0.9rem;
    margin-bottom: 0.6rem;
    color: ${blue};
    font-size: 1.25rem;
    font-family: 'NeueHelveticaPro57Condensed', Helvetica, Arial;
    letter-spacing: 0.05rem;
`;

export const PhoneNumber = styled.a`
    font-size: 0.875rem;
    color: ${darkGreen};
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
`;
