import { Link } from 'gatsby';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import styled from 'styled-components';
import { CommonBullet, CommonBullets } from '../../../styles/Common';
import { deviceMax, deviceMin } from '../../../styles/MediaQuery';
import { blue, containerWidth, greenDarker, greenLighter, white } from '../../../styles/Variables';

export const SliderContainer = styled.div`
    position: relative;
    overflow: hidden;
    height: 356px;
    width: 100%;

    @media ${deviceMax.tablet} {
        height: 550px;
    }

    @media (max-width: 650px) {
        height: 490px;
    }

    @media ${deviceMax.mobileL} {
        height: 450px;
    }

    @media ${deviceMax.mobileM} {
        height: 380px;
    }

    @media ${deviceMax.mobileS} {
        height: 350px;
    }
`;

interface SlideType {
    readonly isActive: boolean;
    readonly position: boolean;
    readonly backgroundUri?: string;
}

export const Slide = styled.div<SlideType>`
    width: 100%;
    height: 100%;
    background-image: ${(props) => `url(${props.backgroundUri})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center right;
    position: absolute;
    display: flex;
    align-items: flex-end;
    top: 0;
    left: 0;
    transition: opacity 0.6s;
    opacity: ${(props) => {
        if (props.isActive) {
            return '1';
        } else {
            return '0';
        }
    }};

    @media ${deviceMax.tablet} {
        height: 300px;
        background-size: cover;
        background-position: top right;
    }

    @media (max-width: 650px) {
        height: 240px;
    }

    @media ${deviceMax.mobileL} {
        height: 200px;
    }

    @media ${deviceMax.mobileM} {
        height: 120px;
    }
`;

interface SlideTextInterface {
    readonly isActive: boolean;
}

export const SlideText = styled.div<SlideTextInterface>`
    width: ${containerWidth};
    max-width: 100%;
    margin: 0 auto 3em;
    position: static;
    z-index: ${(props) => {
        if (props.isActive) {
            return '1';
        } else {
            return '0';
        }
    }};
    * {
        color: ${white};
    }

    @media ${deviceMax.tablet} {
        ${(props) =>
            props.isActive
                ? {
                      pointerEvents: 'unset',
                      opacity: '1',
                      zIndex: '1',
                  }
                : {
                      pointerEvents: 'none',
                      opacity: '0',
                      zIndex: '0',
                  }};
        * {
            color: ${blue};
        }
        position: absolute;
        top: calc(100% + 45px);
        left: 50%;
        transform: translateX(-50%);
        transition: opacity 0.6s;
        width: calc(100% - 2em);
        margin-bottom: 2em;
    }

    @media ${deviceMax.tabletL} {
        width: calc(100% - 20px);
    }

    @media ${deviceMax.mobileL} {
        top: calc(100% + 25px);
    }
`;

export const SlideHeader = styled.div`
    h2 {
        font-family: 'NeueHelveticaPro77CondensedBold', Helvetica, sans-serif;
        font-weight: 100;
        font-size: 2.25rem;
        line-height: 2.75rem;
        letter-spacing: 0.025em;
        max-width: 500px;
        margin: 0;
        &:last-child {
            margin-bottom: 0.5rem;
        }

        @media ${deviceMax.mobileL} {
            font-size: 2rem;
            line-height: 2.5rem;
        }

        @media ${deviceMax.tablet} {
            &:last-child {
                margin-bottom: 0.5em;
            }
        }
    }

    img {
        max-width: 300px;
    }
`;

export const SlideLink = styled(Link)`
    font-family: 'NeueHelveticaPro77CondensedBold', Helvetica, sans-serif;
    display: flex;
    align-items: flex-end;
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 600;

    @media ${deviceMax.mobileL} {
        font-size: 1.25rem;
    }
`;

export const SlideArrowWrapper = styled.div`
    border-radius: 50%;
    min-width: 3em;
    min-height: 3em;
    background: linear-gradient(${greenLighter}, ${greenDarker});
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1em;
    &:hover {
        background: ${greenDarker};
    }
`;

export const SlideArrow = styled(AiOutlineArrowRight)`
    fill: ${white};
    font-size: 1.5em;
`;

const SliderNavigationArrowWrapper = styled.div`
    border-radius: 50%;
    width: 3em;
    height: 3em;
    background: rgba(0, 0, 0, 0.3);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 30%;

    &:hover {
        background: rgba(0, 0, 0, 0.6);
    }

    @media ${deviceMin.tablet} {
        display: none;
    }

    @media ${deviceMax.mobileL} {
        top: 20%;
    }

    @media ${deviceMax.mobileM} {
        top: 10%;
    }
`;

export const LeftArrowWrapper = styled(SliderNavigationArrowWrapper)`
    left: 1em;
`;

export const RightArrowWrapper = styled(SliderNavigationArrowWrapper)`
    right: 1em;
`;

export const LeftArrow = styled(BsChevronLeft)`
    fill: ${white};
    font-size: 1.5em;
    margin-right: 0.125em;
`;

export const RightArrow = styled(BsChevronRight)`
    fill: ${white};
    font-size: 1.5em;
    margin-left: 0.125em;
`;

export const Bullets = styled(CommonBullets)`
    width: 100%;

    @media ${deviceMax.tablet} {
        display: none;
    }
`;

export const Bullet = styled(CommonBullet)``;
